<template>
  <input type="checkbox" id="menu" ref="menuCheckbox" />

  <header class="main-navigation" id="main-navigation">
    <label for="menu">
      <div></div>
      <div></div>
      <div></div>
    </label>
    <div class="container d-flex">
      <div class="logo">
        <router-link class="menu-link" to="/"><img src="/img/logo.png" alt="logo" /></router-link>
      </div>

      <nav>
        <ul>
          <li class="nav-item" @click="closeMenu"><router-link to="/">Úvod</router-link></li>
          <li class="nav-item" @click="closeMenu">
            <router-link to="/about">O mně</router-link>
          </li>
          <li class="nav-item" @click="closeMenu">
            <router-link to="/showreel">Showreel</router-link>
          </li>
          <li class="nav-item" @click="closeMenu">
            <router-link to="/svatby">Svatební natáčení</router-link>
          </li>

          <li class="nav-item" @click="closeMenu">
            <router-link to="/promo">Promovidea</router-link>
          </li>
          <li class="nav-item" @click="closeMenu">
            <router-link to="/backstage">BackStage</router-link>
          </li>
          <li class="nav-item" @click="closeMenu">
            <router-link to="/kontakt">Kontakt</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </header>

  <router-view />
  <footer class="footer" id="contact">
    <div class="container">
      <p>
        <span id="currentYear">Vytvořil <a href="mailto:sladky.honza@gmail.com">Jan Sladký</a></span>
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "App",

  components: {},

  data: () => ({}),
  methods: {
    closeMenu() {
      this.$refs.menuCheckbox.checked = false;
    },
  },

  setup() {
    onMounted(() => {
      // Zakáže dvojklik pro přiblížení na celé aplikaci
      document.addEventListener("dblclick", (e) => {
        e.preventDefault();
      });
    });
  },
};

document.addEventListener("DOMContentLoaded", (event) => {
  const currentYear = new Date().getFullYear();
  const copyrightText = `&copy; ${currentYear} Tento web vytvořil Jan Sladký`;
  document.getElementById("currentYear").innerHTML = copyrightText;
});

import { onMounted } from "vue";
</script>

<style lang="scss">
nav {
  //padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
//navigace

input:checked + header nav {
  height: 75vh;
}
input:checked + header label div:nth-child(1) {
  transform: rotate(45deg);
  top: 50%;
}
input:checked + header label div:nth-child(3) {
  transform: rotate(-45deg);
  top: 50%;
}
input:checked + header label div:nth-child(2) {
  transform: scale(0);
}

.main-navigation {
  height: 80px;
  background-color: #f1f1f1;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 2rem;
  align-items: center;
  box-shadow: rgba(149, 157, 165, 0.2) 0px -2px 24px;

  position: absolute;
  top: 0;
  left: 50%; // přidáno pro centrování
  transform: translateX(-50%); // přidáno pro centrování
  display: flex;

  z-index: 2;

  &.fixed {
    position: fixed;
    top: 0;
    left: 50%; // přidáno pro centrování
    transform: translateX(-50%); // přidáno pro centrování
    width: 100%;
    z-index: 1000;
  }
  label {
    display: inline-block;
    width: 45px;
    height: 30px;
    position: relative;
    cursor: pointer;
    margin-right: auto;
    display: inline-block;
    div {
      width: 100%;
      height: 5px;
      background: #000;
      position: absolute;
      transform: translateY(-50%);
      border-radius: 99px;
      transition: 0.3s;
      transform: scale(1);
      &:first-child {
        top: 0;
      }
      &:nth-child(2) {
        top: 50%;
      }
      &:nth-child(3) {
        top: 100%;
      }
    }
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto !important;

    img {
      width: 170px;

      height: auto;
      transition: transform 0.2s ease-in-out;
      &:hover {
        transform: rotate(-5deg) scale(1.1);
      }
      @media (min-width: 576px) {
        width: 190px;
      }
    }
  }

  nav {
    top: 80px;
    position: absolute;
    left: 0;
    background: #fff;
    width: 100%;
    z-index: 2;
    overflow: hidden;
    height: 0;
    transition: 0.2s;
    margin: 0 auto;

    ul {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 0px;
      transition: 0.2s;
      gap: 5px;

      li {
        list-style-type: none;
        padding: 15px 40px;
        a {
          text-decoration: none;
          color: #000;
          //margin-right: 20px;
          display: inline-block;
          //margin-top: 20px;
          //margin-bottom: 20px;

          /* &:nth-child(1) {
                  margin-top: 20px;
               } */
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 576px) {
  .main-navigation {
    .container {
      nav {
        ul {
          li.nav-item  {
            padding: 0px;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 768px) {
  body.homepage {
    main {
      .tabs-wrapper {
        .tabs {
          .main-h2-title {
            margin-top: 0px;
          }
        }
      }
    }
    input:checked + header nav {
      height: 80vh;
    }
  }
  .main-navigation {
    .container {
      align-items: center;
    }

    nav {
      justify-content: center;
      ul {
        display: flex;
        justify-content: center;
        //flex-direction: row;
        margin-bottom: 0px;

        li.nav-item {
        }
      }
    }
  }
  footer.footer {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    .footer-logo {
      img {
        width: 345px;
      }
    }
  }
  .footer-logo,
  .footer-contact,
  .footer-map {
    flex: 0 0 calc(50% - 40px);
  }
}
@media screen and (min-width: 1050px) {
  header.main-navigation {
    .logo {
      margin-right: 170px !important;
      a {
        width: 100px;
      }
    }
    nav {
      position: unset;
      top: unset;
      left: unset;
      overflow: unset;
      align-items: center;
      display: flex;
      ul {
        flex-direction: row;
        li {
          padding: 0;
          a {
            text-decoration: none;
            color: #000;
            margin-right: 20px;
            &.router-link-active.router-link-exact-active {
              background: #000;
              padding: 5px 10px;
              border-radius: 5px;
              font-weight: bold;
              text-decoration: none;
              border-bottom: 2px solid transparent;
              text-decoration: none;
              border-bottom: none;
              color: #fff;
            }

            &:hover {
              animation: pulse; /* referring directly to the animation's @keyframe declaration */
              animation-duration: 0.3s; /* don't forget to set a duration! */
            }
          }
        }
      }
    }
    label {
      width: 40px;
      height: 20px;
      position: relative;
      cursor: pointer;
      margin-right: auto;
      display: inline-block;
      display: none;
    }
  }
  .footer-logo,
  .footer-contact,
  .footer-map {
    flex: 0 0 calc(33% - 40px);
  }
}

footer.footer {
  //font-size: 22px;
  background: #000;
  display: flex;
  color: #fff;
  max-width: 100%;
  height: 80px;

  align-items: center;

  flex-direction: column;

  gap: 40px;
  justify-content: center;

  .footer-logo {
    img {
      width: 255px;
      border-radius: 100%;
      aspect-ratio: 1/1;
      object-fit: cover;
      box-shadow: 0 7px 30px 2px rgba(148, 149, 150, 0.1);
    }
  }
  .footer-contact {
    .footer-contact-up {
      display: flex;
    }
    .footer-contact-down {
      display: flex;
      flex-direction: column;

      .tel,
      .mail {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        a {
          color: #000;
        }

        i {
          margin-right: 10px;
          &::before {
            color: #9fbef5;
            width: 53px;
            height: 53px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #000;
            z-index: 1;
            //font-size: 32px;
          }
        }
      }
    }
  }
}
</style>
