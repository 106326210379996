<template>
  <div class="main-container" v-fade-in>
    <div class="content-container">
      <div>
        <!-- Obrázky s data atributy pro GLightbox -->
        <a v-for="(img, index) in images" :key="index" :href="img" class="glightbox" data-gallery="gallery">
          <img :src="img" class="thumbnail" />
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import GLightbox from "glightbox";
import "glightbox/dist/css/glightbox.css";

export default {
  data() {
    return {
      // Dynamicky generované pole cest k obrázkům
      images: Array.from({ length: 60 }, (_, i) => `/img/backstage/${i + 1}.jpg`),
    };
  },

  mounted() {
    // Inicializace GLightboxu po vykreslení komponenty
    this.lightbox = GLightbox({
      selector: ".glightbox",
    });
  },
  beforeUnmount() {
    // Zničení lightbox instance při odmountování komponenty
    this.lightbox.destroy();
  },
  methods: {
    initLightbox() {
      // Inicializace GLightboxu
      this.lightbox = GLightbox({
        selector: ".glightbox",
        loop: true, // Přidání smyčky pro procházení obrázků
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.thumbnail {
  width: 100px;
  height: 100px;
  margin: 5px;
  cursor: pointer;
}

.fade-in {
  opacity: 1; /* Zobraz obsah */
}
</style>
