// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore"; // Import Firestore
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDcK9_isaSjQM8tsmvhD8EKn0c0CM0xV2w",
  authDomain: "videojinak-1412d.firebaseapp.com",
  databaseURL: "https://videojinak-1412d-default-rtdb.firebaseio.com",
  projectId: "videojinak-1412d",
  storageBucket: "videojinak-1412d.firebasestorage.app",
  messagingSenderId: "343265435769",
  appId: "1:343265435769:web:d573c7597bdfe36357f4a8",
  measurementId: "G-QHS0T8J6M3",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Správné získání instance Firestore
const analytics = getAnalytics(app);

export { db };
