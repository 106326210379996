<template>
  <div class="main-container" v-fade-in>
    <div class="content-container">
      <h2>Sestřih nejzajímavějších videí, na kterých jsme pracovali …</h2>
      <div class="iframe-wrapper">
        <iframe width="100%" height="500" src="https://www.youtube.com/embed/HGCtCazEWxY?si=LgAm-gq2hFExCC_e" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
    .content-container {
        h2 {
            margin-bottom: 20px;
        }
    }
</style>
