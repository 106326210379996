import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import "./assets/style.scss";
import fadeInDirective from "@/directives/fade-in.directive"; // Importuj globální direktivu

loadFonts();

const app = createApp(App);

// Registrování globální direktivy
app.directive("fade-in", fadeInDirective);

// Použití routeru, store a vuetify
app.use(router);
app.use(store);
app.use(vuetify);

// Mountování aplikace
app.mount("#app");
