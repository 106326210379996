<template>
  <div class="title-video" v-fade-in>
    <img
      v-if="isMobile"
      src="/img/title-img-video-mobil.jpg"
      class="video-preview"
      alt="Úvodní obrázek než se načte video na mobilu"
      ref="videoPreview"
    />
    <img
      v-else
      src="/img/title-img-video-desktop.jpg"
      class="video-preview"
      alt="Úvodní obrázek než se načte video na desktopu"
      ref="videoPreview"
    />
    <!-- Video pro mobil -->
    <video v-if="isMobile" autoplay muted loop playsinline class="background-video mobil-video" ref="backgroundVideo">
      <source src="/video/title-video-mobil.mp4" type="video/mp4" />
      Váš prohlížeč nepodporuje video tag.
    </video>
    <!-- Video pro desktop -->
    <video v-else autoplay muted loop playsinline class="background-video desktop-video" ref="backgroundVideo">
      <source src="/video/title-video-desktop.mp4" type="video/mp4" />
      Váš prohlížeč nepodporuje video tag.
    </video>
    <div class="video-text">
      Specializujeme se na promo, klipy, komerční i svatební videa. Nejraději děláme akčnější videa, a když do nich
      můžeme promítnout i srandu, pak jedině super
      <div class="title-buttons-read-more">
        <v-btn class="read-more-btn" :to="{ name: 'svatby' }">Svatby</v-btn>
        <v-btn class="read-more-btn" :to="{ name: 'promo' }">Promo</v-btn>
        <v-btn class="read-more-btn" @click="scrollToReviews">Recenze</v-btn>
      </div>
    </div>
  </div>

  <!-- Sekce pro recenze -->
  <v-container v-show="showReviews" class="reviews-section-wrapper" fluid ref="reviewsSection">
    <h2>Recenze</h2>
    <div class="reviews-section">
      <v-carousel
        show-arrows="hover"
        class="v-carousel"
        cycle
        hide-delimiter-background
        height="400"
        progress="primary"
      >
        <v-carousel-item v-for="(review, i) in reviews" :key="i">
          <v-sheet color="light" height="100%">
            <div class="d-flex fill-height justify-center align-center text-center px-4">
              <div>
                <h3 class="">{{ review.text }}</h3>
                <p>{{ review.sign }}</p>
              </div>
            </div>
          </v-sheet>
        </v-carousel-item>
      </v-carousel>
    </div>
    <v-btn @click="showReviews = false" class="close-button">Zpět na úvod</v-btn>
  </v-container>
</template>

<script>
import { onMounted, ref, nextTick, computed } from "vue";

export default {
  setup() {
    const videoPreview = ref(null);
    const backgroundVideo = ref(null);
    const showReviews = ref(false);
    const reviewsSection = ref(null);

    const isMobile = computed(() => window.innerWidth < 992); // Kontroluje šířku okna
    const reviews = ref([
      {
        id: 1,
        text: "Naprosto bezkonkurenční! Měli jsme na svatbě víc jak půlku hostů z Irska a ti dodnes mluví o nejlepší svatbě a to především právě diky klukům z Videojinak!",
        sign: "Nelli Přibáňová (svatba)",
      },
      {
        id: 2,
        text: "Je na 100% jasný, že s takovým profíkem budeme letos znova točit a s nesmírnou chutí!!! Lukáš je super profesionál na nápady a kameru.",
        sign: "Ondřej Demut (promo automobilových závodů)",
      },
      {
        id: 3,
        text: "Termín svatby jsme vybírali dle možnosti natáčení, protože svatbu bez videa od nich nechcete :) Doporučuju všem!",
        sign: "Nella Šolcová (svatba)",
      },
      {
        id: 4,
        text: "Šimi nám točil v létě svatbičku, a taky točil svatbičku ségře, a kámošce, a ... a to vlastně asi vypovídá o všem  Dáváme hodnocení 1853 ze 100!",
        sign: "Áňa Vaníčková (svatba)",
      },
      {
        id: 5,
        text: "Kluci nám natáčeli firemní promo video a výsledek, rychlost a osobní přístup byl na top úrovni. Do budoucna cokoliv už jen s VIDEOJINAK.",
        sign: "Lukáš Málek (promo na restauraci)",
      },
      {
        id: 6,
        text: "Skvělý výsledný videa, natáčení je ohromná sranda. Kluci z Videojinak jsou fakt boží a asi se rozvedu jen abych je mohl znovu pozvat na svatbu ",
        sign: "Michal Holzknecht (svatba)",
      },
      {
        id: 7,
        text: "Pokud si myslíte, že Videojinak budeme neustále doporučovat, tak se mýlíte. Nebudeme. Protože pak by neměli čas na nás ",
        sign: "Tomáš Krs (promospot pro KRS Auto - Mercedez)",
      },
      {
        id: 8,
        text: "Kluci zapadli mezi naší rodinu - jako by tam prostě patřili. Neměnila bych a všem můžu velmi doporučit. A jim znova děkuji ",
        sign: "Petra Honzíková (svatba)",
      },
      {
        id: 9,
        text: "Videa jsou netradiční, zábavná, vtipná a tak jak vypadají videa, tak ve stejném modu probíhá i natáčení. Prostě v dokonalé pohodě protkané neustálým humorem!",
        sign: "Gabriela Kotoucova (svatba)",
      },
      {
        id: 10,
        text: "Kluci mají skvělý cit vytvořit něco, co vyvolá v člověku emoci a zanechá stopu. Dělali jsme několik projektů a vždy skvěle a mega profesionálně odvedená práce. Za mě 100 bludišťáků ",
        sign: "Extreme Hobby (akční promo spoty OKTAGON)",
      },
      {
        id: 11,
        text: "Videa vytvořená Lukášem jsou hravá, vtipná, profesionální, zároveň vždy protkaná osobním přístupem a originalitou jemu vlastní! Proto naše profi spolupráce přerostla v přátelství ",
        sign: "Dagmar Pavlová (COTY - kosmetická videa)",
      },
      {
        id: 12,
        text: "Nejednomu zaměstnanci ukápla slza dojetím po shlédnutí promo videa, přestože během natáčení nás bolela břicha od smíchu...",
        sign: "Jan Podsednik (Bohemia Hop – video o chmelu)",
      },
      {
        id: 13,
        text: "S každým rokem zvyšují svoji technickou kvalitu a ani po všech těch letech jim nechybí elán a dobré nápady.",
        sign: "Marek Neklan (režisér, spolunatáčení filmečků)",
      },
      {
        id: 14,
        text: "Vždy vyrazí dech. Profesionální a originální práce s lidským přístupem. Vždy vše s úsměvem a neskutečnou grácií. Doporučuji všemi deseti. Stojí to za to.",
        sign: "Martina Patočková (svatba)",
      },
      {
        id: 15,
        text: "Dodnes i díky videu k nám v létě přijíždí spousta nadšených mladých jezdkyň. Lukáš prostě vykouzlil neskutečný výsledek! Proto se taky chystáme na další kousek ",
        sign: "Eva Podrabská (dokument o koních)",
      },
      {
        id: 16,
        text: "Opravdu velký kouzelník s kamerou a videem, který má v sobě spoustu nápadů, kreativity a zkušeností.",
        sign: "Lukáš Budai (moderátor)",
      },
      {
        id: 17,
        text: "Kluci byli naprosto profesionální, milí a natáčení s nimi byla jedna obrovská legrace!",
        sign: "Michaela Dinhová (kaskadérka, akční film BLBEJ DEN)",
      },
      {
        id: 18,
        text: "Jako marketingový manažer nadnárodní značky dekorativní kosmetiky mohu spolupráci s Videojinak jen doporučit. Jejich přístup, produkt, služby a vybavení jsou na profesionální úrovni.",
        sign: "Kristyna Dierstein (COTY - kosmetická videa)",
      },
      {
        id: 19,
        text: "Šaty použijete jednou, květiny zvadnou, hostina se sní... ale tuhle úžasnou vzpomínku si budete pouštět celý život... a pokud nechcete časem u sledování vašeho svatebního videa umřít nudou, objednejte si VIDEOJINAK!!",
        sign: "Tereza Relichová (svatba)",
      },
      {
        id: 20,
        text: "V životě nikdy nebudu litovat toho, že jsem se rozhodla na naši svatbu pro kluky z Videojinak, byť jsem zprvu váhala, jelikož jsou z opačného konce republiky. Bylo to ale moje nejlepší životní rozhodnutí ",
        sign: "Katerina Miczkova (svatba)",
      },
      {
        id: 21,
        text: "Tak pozor, tohle nejsou obyčejná svatební videa. Lukáš je strašně šikovnej a svojí bezvadnou náladou Vás dokáže uvolnit a dostat z Vás to nejlepší.",
        sign: "Kristina Bímová (svatba)",
      },
    ]);

    onMounted(async () => {
      await nextTick();
      if (backgroundVideo.value) {
        backgroundVideo.value.addEventListener("canplay", () => {
          if (videoPreview.value) videoPreview.value.style.display = "none";
          backgroundVideo.value.style.display = "block";
        });
      }
    });

    const scrollToReviews = async () => {
      showReviews.value = true;
      await nextTick();
      const section = reviewsSection.value?.$el || reviewsSection.value;
      if (section && section.getBoundingClientRect) {
        const offsetTop = section.getBoundingClientRect().top + window.pageYOffset;
        window.scrollTo({
          top: offsetTop,
          behavior: "smooth",
        });
      } else {
        console.warn("Sekce recenzí není k dispozici nebo nebyla správně načtena.");
      }
    };

    return {
      videoPreview,
      backgroundVideo,
      showReviews,
      reviews,
      isMobile,
      scrollToReviews,
      reviewsSection,
    };
  },
};
</script>

<style lang="scss">
.title-video {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;

  .read-more-btn {
    display: block;
    text-align: center;
    display: flex;
    align-items: center;
    margin-top: 10px;
  }

  .video-text {
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 20px;
    position: absolute;
    bottom: -30px;
    border-radius: 10px;
    margin: 0 auto;
    left: 50%;
    transform: translate(-50%, -50%);

    .title-buttons-read-more {
      display: flex;
      justify-content: center;
      gap: 10px;
    }
  }

  .video-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }

  .background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    display: none;
  }

  .mobil-video {
    display: block;

    @media (min-width: 992px) {
      display: none;
    }
  }

  .desktop-video {
    display: none;

    @media (min-width: 992px) {
      display: block;
    }
  }
}

.reviews-section-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  background-color: #000;
  color: white;
  text-align: center;
  overflow: hidden;
  z-index: 1000;

  .v-btn {
    span {
      i {
        color: #000;
      }
    }
  }

  .reviews-section {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .close-button {
    background-color: #fff;
    color: #000;
    margin-top: 20px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }
}
</style>
