<template>
  <div class="main-container" v-fade-in>
    <div class="content-container">
      <div class="about-wrapper">
        <div class="about-img">
          <img src="./img/about.jpg" alt="To jsem já" />
        </div>
        <div class="about-text">
          <h3>„Užívej život, ať máš v důchoďáku co vyprávět“</h3>
          <p>
            Jmenuju se Lukáš Šimandl a rád se směju :- D K natáčení jsem se dostal po vojně v roce 2000 a od té doby se
            filmařině věnuji. V roce 2010 jsem si založil společnost Videojinak a pustil se do natáčení na plný pecky.
            :- ) U svatebních videí jsem si vždy říkal, že v Čechách je spousta parádních kameramanů, jejich videa jsou
            luxusní, ale … na mě prostě moc romantická. A jelikož prý mám talent bavit lidi, tak jsem toto chtěl
            zakomponovat právě do svatebních videí. A to se povedlo. Spousta klientů mi říká, že si naše natáčení
            totálně užili a za to jsme rádi. : ) Mám také rád bojová umění a sporty, a proto je také v mé tvorbě hodně
            uvidíte. Ať jde o záznamy z akcí typu Oktagon, či krátké akční filmečky. Nejradši se ale směju, a tak
            tvoříme s mojí bandou spousty videí na odreagování… v naší dílně vznikl například JÚTUBER JOŽKA. :- ) „Moje
            práce je můj koníček“ … Kdo vlastně tohle může říct? … Já určitě jo. :- )
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script></script>
<style lang="scss">
.about-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 50px;
  box-sizing: border-box;
  justify-content: center;

  .about-img {
    
    img {
      width: 100%;
      border-radius: 10px;
      max-width: 100%;
      height: auto;
    }

    flex: 0 0 calc(100% - 50px);
    max-width: calc(100% - 50px);
    @media (min-width: 992px) {
      flex: 0 0 calc(50% - 50px);
      max-width: calc(50% - 50px);
    }
  }
  .about-text {
    flex: 0 0 calc(100% - 50px);
    max-width: calc(100% - 50px);
    @media (min-width: 992px) {
      flex: 0 0 calc(50% - 50px);
      max-width: calc(50% - 50px);
    }
    h3 {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}
</style>
