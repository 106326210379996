<template>
  <div class="main-container" v-fade-in>
    <div class="content-container">
      <p>
        Jestliže hledáte někoho, kdo vám jen nějak natočí svatbu, tak to tady nebudete správně. 🙂 Na svatebních videích
        si fakt dáváme záležet… Chceme abyste se po shlédnutí regulérně smáli, aby vás video pobavilo, prostě abyste si
        na svatbu díky našemu videjku zavzpomínali jako na parádní párty a ještě vám k tomu ukápla i nějaká ta slza. V
        Čechách je spousta profi kameramanů, kteří natáčí nadherná svatební videa, ale …. je to prostě všechno moc
        romantický. U nás romantika taky, ale spíš až na druhé koleji. 🙂 V rámci svatebního dne potřebujeme mít
        novomaželé na hoďku pro sebe, kde natáčíme ty naše „Videojinak“ záběry.
      </p>
      <div class="showreel-video-wrapper">
        <iframe
          src="https://www.youtube.com/embed/IPnMLNMQhG0?si=TuvngY2D2gju3Hy8"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </div>
      <h2>Co se týče variant natáčení, tak nabízíme 2:</h2>
      <h3>Verze klip</h3>
      <p>
        Natáčet jezdíme 2 kameramani, já si hlavně natáčím naše „Videojinak“ záběry do videoklipu a kolega zachycuje
        průběh celého dne, aby nic důležitého neuniklo. 10hodin natáčení. Ve finále od nás svatebčané dostanou svatební
        videoklip sestříhaný na jeden song + bonus „V JEDNÉ MINUTĚ“, kde je celá svatba dynamicky našmikaná do 60
        vteřin, vše ve 4K.
      </p>
      <h3>Verze klip + záznam</h3>
      <p>
        Stejné jak u KLASIKA verze, ale navíc svatebčané dostanou i profi sestříhaný záznam celého dne v délce cca 25-30
        minut.
      </p>
      <p>
        K natáčení používáme moderní videotechniku od značek SONY, DJI, BLACKMAGIC a GOPRO. Určitě vám můžeme zaručit,
        že naše natáčení vás opravdu bude bavit … a nás taky. 🙂 Ceníky možno poslat e-mailem (videojinak@centrum.cz).
      </p>

      <h3>Ukázky z natáčení svateb</h3>

      <div class="svatby-video-wrapper">
        <iframe
          v-for="(video, index) in displayedVideos"
          :key="index"
          :src="video"
          title="Svatební video"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
        <v-btn size="x-large" class="more-video-btn" @click="loadMoreVideos">Načíst další videa</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      allVideos: [
        "https://www.youtube.com/embed/PSCz_A-dpa8?si=GGOuOphEnjmOYGdZ",
        "https://www.youtube.com/embed/OZi45NhJc_8?si=okka_ZDRhzfqyFAA",
        "https://www.youtube.com/embed/uLNdEZpaFXY?si=77eNU5BdCUM1_3Sc",
        "https://www.youtube.com/embed/Mg29bfkROdQ?si=5w6pICbCZPj10Vqy",
        "https://www.youtube.com/embed/ovqWelH_j-w?si=JoKHKK5_RN_LJGzM",
        "https://www.youtube.com/embed/LPKPueX_GEc?si=g-XbiEiHClhslNOP",
        "https://www.youtube.com/embed/k9qG2IYv7ds?si=xalhWyMtmjwa6cOl",
        "https://player.vimeo.com/video/239808483?h=6cc9b901b0",
        "https://www.youtube.com/embed/CaKMveQYslM?si=LI6Pf8okHiTbmEAk",
        "https://www.youtube.com/embed/_y-V2tfMLno?si=uBheOnlqKd_FNrsQ",
        "https://www.youtube.com/embed/BAN-yBBd6gk?si=9D6xSHReFy8aW3q_",
        "https://player.vimeo.com/video/165786558?h=9ee524c868",
        "https://www.youtube.com/embed/tquC3OkyuKg?si=UOGxvOxqPDFMEYI8",
        "https://www.youtube.com/embed/YIbs8-UcR9s?si=dTsbdqgvmmywH3my",
        "https://www.youtube.com/embed/aPJ4SfWVhlA?si=2gBT61lp_Em2ceFK",
        "https://www.youtube.com/embed/SZvxOilLdao?si=k0nmO0LwxZJGSlzp",
        "https://www.youtube.com/embed/Qu525UTXd0M?si=4snNLNqTd-YEcg-J",
        "https://www.youtube.com/embed/hDws43hsmzg?si=MYHk9bo9k4QXb-ak",
        "https://www.youtube.com/embed/es-XRY--Pzs?si=YEvstR6fRmPouyD7",
        "https://www.youtube.com/embed/2JQNmQAtEFU?si=Jbiq9dHNut5vavmA",
        "https://www.youtube.com/embed/att9Jmbkn6E?si=ZiAUO8QKDLi1EfqD",
        "https://www.youtube.com/embed/L8Fe0bgkdZQ?si=i7ubh4iUIuHR-Ncf",
        "https://www.youtube.com/embed/D8nbB1t-wMk?si=sNCTL-znD4e9YAUo",
        "https://www.youtube.com/embed/mO_ujeCW3iU?si=0OqT37HJFSQdWVax",
        "https://www.youtube.com/embed/xrpciZJByxc?si=RuwhVLeASFuIa9gk",
        "https://www.youtube.com/embed/LmOZt5G1M0s?si=GBvaXPnLuaBq_qXp",
        "https://www.youtube.com/embed/-D7CYTVFbEE?si=RWGN8lVcLmR-gQIB",
        "https://www.youtube.com/embed/XnuwwUWS7dI?si=iw9OPNr1pjVfgwD3",
        "https://www.youtube.com/embed/CSWxcRjIknI?si=7dyy-xCYNuC59CnO",
        "https://www.youtube.com/embed/4UqShsE8yog?si=YOvFnrDSwTkRgx25",
        "https://www.youtube.com/embed/9_vBjrVkgcs?si=8nLYtOQlaP30u23A",
        "https://www.youtube.com/embed/CNCKnt7ejFM?si=ehiiEfIRfXCUfU3U",
        "https://www.youtube.com/embed/ctPSB05MZ1c?si=a-WiKBh22bC9TDkr",
      ],
      displayedVideos: [], // Pole pro zobrazení videí
      videosToShow: 6, // Počet videí, která se mají zobrazit najednou
    };
  },
  methods: {
    loadMoreVideos() {
      const nextVideos = this.allVideos.slice(
        this.displayedVideos.length,
        this.displayedVideos.length + this.videosToShow
      );
      this.displayedVideos.push(...nextVideos);
    },
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.displayedVideos = this.allVideos.slice(0, this.videosToShow);
    } else {
      this.loadMoreVideos();
    }
    /* this.loadMoreVideos(); // Načtěte první videa při načtení komponenty
    window.scrollTo(0, 0); */
  },
};
</script>

<style lang="scss">
.content-container {
  p {
    margin-top: 10px;
  }
  h2 {
    margin-top: 20px;
  }
  h3 {
    margin-top: 20px;
  }
}
.showreel-video-wrapper {
  position: relative; /* Přidání pozice */
  padding-bottom: 56.25%; /* Poměr 16:9 (výška / šířka * 100) */
  height: 0; /* Skryje obsah */
  overflow: hidden; /* Skrýt přetékání */
  width: 100%; /* Zajištění, že wrapper má 100% šířku */
  max-width: 100%;
  margin-top: 30px;
  margin-bottom: 50px;

  iframe {
    position: absolute; /* Pozicování iframe */
    top: 0;
    left: 0;
    width: 100%; /* Šířka 100% */
    height: 100%; /* Výška 100% */
  }
}
.more-video-btn {
  margin-top: 20px;
  margin-bottom: 50px;
}
.svatby-video-wrapper {
  display: flex;
  flex-wrap: wrap;

  max-width: 100%;
  gap: 10px;

  iframe {
    flex: 0 0 100%;
    @media (min-width: 762px) {
      flex: 0 0 calc(50% - 5px);
      max-width: calc(50% - 5px);
    }
    @media (min-width: 992px) {
      flex: 0 0 calc(33% - 5px);
      max-width: calc(33% - 5px);
    }
  }
}
</style>
