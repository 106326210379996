import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import SvatbyView from "../views/SvatbyView.vue";
import AboutView from "../views/AboutView.vue";
import ShowReelView from "../views/ShowReelView.vue";
import PromoVideoView from "../views/PromoVideoView.vue";
import BackStageView from "../views/BackStageView.vue";
import KontaktView from "../views/KontaktView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/svatby",
    name: "svatby",
    component: SvatbyView,
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
  },
  {
    path: "/showreel",
    name: "showreel",
    component: ShowReelView,
  },
  {
    path: "/promo",
    name: "promo",
    component: PromoVideoView,
  },
  {
    path: "/backstage",
    name: "backstage",
    component: BackStageView,
  },
  {
    path: "/kontakt",
    name: "kontakt",
    component: KontaktView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // Pokud není uložena pozice, nebo je přechod na jinou stránku
    if (savedPosition) {
      return savedPosition; // Pokračuj na místo, kde uživatel byl před přechodem
    } else {
      return { top: 0 }; // Vždy se posuň na začátek stránky
    }
  },
});

export default router;
